import dayjs from 'dayjs';
import { Fragment } from 'react';

import { Grid } from '@mui/material';
import CachedIcon from '@mui/icons-material/Cached';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import AutoFixNormalIcon from '@mui/icons-material/AutoFixNormal';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';

import { Autocomplete, Button, TextField } from 'shared';
import { matches } from 'components/Inventory/utils/matches';
import { forReviewFiltersStyles } from 'components/Inventory/style/style';
import { types } from 'components/Inventory/utils/forReviewTransactionTypes';

const ForReviewFilters = ({
    data,
    banks,
    values,
    handleReset,
    handleChange,
    setFieldValue,
    handleAIMatch,
    autoMatchLoading,
    handleClearClick,
    handleStartAutoMatch,
}) => {
    const classes = forReviewFiltersStyles();

    return (
        <Fragment>
            <Grid container spacing={2}>
                <Grid item xs={12} md={6} lg={3}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                            value={values.startDate && dayjs(values.startDate)}
                            onChange={(date) => setFieldValue('startDate', date)}
                            label="Start Date"
                            placeholder="Start Date"
                            format="YYYY-MM-DD"
                            sx={classes.datePicker}
                        />
                    </LocalizationProvider>
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                            value={values.endDate && dayjs(values.endDate)}
                            onChange={(date) => setFieldValue('endDate', date)}
                            label="End Date"
                            placeholder="End Date"
                            format="YYYY-MM-DD"
                            sx={classes.datePicker}
                        />
                    </LocalizationProvider>
                </Grid>
                <Grid item xs={12} md={12} lg={6}>
                    <TextField
                        fullWidth
                        name="description"
                        inlineLabel="Description"
                        value={values.description}
                        placeholder="Search by description"
                        onChange={handleChange}
                        customStyle={classes.inputField}
                    />
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                    <Autocomplete
                        options={banks}
                        labelKey="label"
                        valueKey="value"
                        placeholder="Bank Account"
                        name="bankAccount"
                        inlineLabel="Bank Account"
                        value={values.bankAccount}
                        onChange={(e, value) => setFieldValue('bankAccount', value)}
                        isSearchField={false}
                        disableClearable
                        customStyle={classes.dropdown}
                        showTotal={true}
                    />
                </Grid>
                <Grid item xs={12} md={6} lg={2}>
                    <Autocomplete
                        options={matches}
                        labelKey="label"
                        valueKey="value"
                        placeholder="Has Matches"
                        name="hasMatches"
                        inlineLabel="Has Matches"
                        value={values.hasMatches}
                        onChange={(e, value) => setFieldValue('hasMatches', value)}
                        isSearchField={false}
                        disableClearable
                        customStyle={classes.dropdown}
                    />
                </Grid>
                <Grid item xs={12} md={6} lg={2}>
                    <TextField
                        fullWidth
                        name="amount"
                        inlineLabel="Amount"
                        type="number"
                        value={values.amount}
                        placeholder="Search by amount"
                        onChange={handleChange}
                        customStyle={classes.inputField}
                    />
                </Grid>
                <Grid item xs={12} md={6} lg={2}>
                    <Autocomplete
                        options={types}
                        labelKey="label"
                        valueKey="value"
                        placeholder="Type"
                        name="type"
                        inlineLabel="Type"
                        value={values.type}
                        onChange={(e, value) => setFieldValue('type', value)}
                        isSearchField={false}
                        disableClearable
                        customStyle={classes.dropdown}
                    />
                </Grid>
            </Grid>

            <Grid container spacing={2} alignItems="center" justifyContent="space-between">
                {data && data?.count > 0 && (
                    <Grid item marginTop="10px" marginBottom="10px">
                        <Button
                            text="Auto Match"
                            startIcon={<CachedIcon />}
                            loading={autoMatchLoading}
                            onClick={handleStartAutoMatch}
                            customStyles={classes.cancelButton}
                        />

                        <Button
                            text="Agent Matching"
                            startIcon={<AutoFixNormalIcon />}
                            loading={autoMatchLoading}
                            onClick={handleAIMatch}
                        />
                    </Grid>
                )}

                <Grid item marginTop="10px" marginBottom="10px" marginLeft="auto">
                    <Button
                        text="Clear"
                        onClick={() => handleClearClick(handleReset)}
                        customStyles={classes.cancelButton}
                    />
                    <Button text="Apply" type="submit" />
                </Grid>
            </Grid>
        </Fragment>
    );
};

export default ForReviewFilters;
